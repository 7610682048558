.counter {
  display: flex;
  background: #f7f8fc;
  border-radius: 4px;

  &.counter-row {
    input {
      width: 48px;
      height: 36px;
      text-align: center;
      border: 1px solid var(--c4);
      border-right: none;
      border-left: none;
      border-radius: 0;
      box-sizing: border-box;
      background: #f7f8fc;
      font-family: JDZhengHT-EN;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #1a1a1a;
      &:focus-visible {
        outline-width: 0;
      }
    }

    i {
      box-sizing: border-box;
    }

    .counter-input-icon {
      width: 25px;
      height: 36px;
      cursor: pointer;
      transition: color 0.3s ease;
      &.counter-add {
        border-radius: 0 var(--r8) var(--r8) 0;
      }

      &.counter-reduce {
        border-radius: var(--r8) 0 0 var(--r8);
      }
    }
    .counter-input-icon:hover {
      cursor: pointer;
      color: red;
    }
  }

  &.counter-column {
    height: 50px;
    flex-direction: row;

    .counter-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    input {
      width: 50px;
      height: 50px;
      text-align: center;
      border-radius: var(--r8) 0 0 var(--r8);
      border: 1px solid var(--c4);
      border-right: none;
      box-sizing: border-box;
      background: #f7f8fc;
      font-family: JDZhengHT-EN;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #1a1a1a;
      &:focus-visible {
        outline-width: 0;
      }
    }

    i {
      box-sizing: border-box;
    }

    .counter-input-icon {
      &.counter-add {
        border-radius: 0 var(--r8) 0 0;
        border-bottom: none;
      }

      &.counter-reduce {
        border-radius: 0 0 var(--r8) 0;
      }
    }
  }

  .counter-input-icon {
    width: 25px;
    height: 36px;
    border: 1px solid var(--c4);

    // &.counter-reduce,
    // &.counter-reduce-enable {
    //   background: url("./assets/icon_reduce_enable.png") no-repeat center/12px 12px;
    // }

    // &.counter-add,
    // &.counter-add-enable {
    //   background: url("./assets/icon_add_enable.png") no-repeat center/12px 12px;
    // }

    // &.counter-reduce,
    // &.counter-reduce-enable {
    //   background: url("./assets/icon_reduce_enable.png") no-repeat center/12px 12px;
    // }

    // &.counter-add,
    // &.counter-add-enable {
    //   background: url("./assets/icon_add_enable.png") no-repeat center/12px 12px;
    // }

    // &.counter-reduce-disable {
    //   background: url("./assets/icon_reduce_disable.png") no-repeat center/12px 12px;
    // }

    // &.counter-add-disable {
    //   background: url("./assets/icon_add_disable.png") no-repeat center/12px 12px;
    // }
  }

  /* chrome浏览器 */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /* 普通IE浏览器 样式清除 */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /* 火狐浏览器样式清除 */

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.small-counter {
  &.counter-row {
    input,
    .counter-input-icon {
      height: 24px;
    }
    input {
      font-size: 14px;
      width: 40px;
    }
    .counter-input-icon {
      width: 16px;
      background-size: 8px 8px;
    }
  }
}

.counter-rtl {
  flex-direction: row-reverse;
  &.counter-row .counter-input-icon.counter-reduce {
    border-radius: 0 var(--r8) var(--r8) 0 !important;
  }

  &.counter-row .counter-input-icon.counter-add {
    border-radius: var(--r8) 0 0 var(--r8) !important;
  }
}
